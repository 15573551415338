import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import PageTitle from '../components/PageTitle'
import { mq, rem } from '../utils/styles'
import icon from '../images/icon-github.svg'

const Container = styled.div`
  margin-top: 60px;

  ${mq.lessThan('mobile')} {
    margin-top: 30px;
  }
`

const Text = styled.div`
  font-size: ${rem(16)};
  margin-top: 30px;

  ${mq.lessThan('mobile')} {
    font-size: ${rem(15)};
  }
`

const Links = styled.ul`
  font-size: 0;
  list-style: none;
  margin-top: 35px;

  li {
    display: inline-block;

    &._github {
      a {
        background: url(${icon}) no-repeat;
        display: block;
        height: 27px;
        width: 27px;

        @media (prefers-color-scheme: dark) {
          filter: invert(100);
        }
      }
    }
  }
`

const AboutPage = () => (
  <Layout>
    <SEO title="About" />
    <Container>
      <PageTitle>About</PageTitle>
      <Text>
        <p
          css={css`
            margin-bottom: 17px;
          `}
        >
          弥永 裕介 / Yusuke Iyonaga
        </p>
        <p>
          1988年生まれ京都在住。フリーランスでWeb制作をしております。
          <br />
          京都のWeb制作会社にてフロントエンド、バックエンド、インフラ周りを幅広く経験した後フリーランスへ転向。
        </p>
      </Text>
      <Links>
        <li className="_github">
          <a
            href="https://github.com/iyonaga"
            target="_blank"
            rel="noopener noreferrer"
          />
        </li>
      </Links>
    </Container>
  </Layout>
)

export default AboutPage
